import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { getTopper } from '../Service/Api';
const TopperX = () => {
    const [classXData, setClassXData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        const topperData = await getTopper();
        const currentYear = new Date().getFullYear();
        const yearString = `${currentYear - 1}-${currentYear}`;
        
        const classX = topperData.filter((item) => item.class === 'X' && item.sessionYear === yearString);
        setClassXData(classX); 
        
        console.log("Filtered class X data for year", yearString, classX);
      };
      fetchData();
    }, []);
  
    const emptyArray = [
      { name: "Student Name", division: "%" },
      { name: "Student Name", division: "%" },
      { name: "Student Name", division: "%" },
      { name: "Student Name", division: "%" }
    ];
  
    const settings = {
      autoplay: true,
      infinite: true, 
      autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false, 
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  return (
     <> 
     <div className="row">
     {classXData.length > 0 ? (
        <Slider {...settings}> 
          {classXData.map((item1, index) => (
            <div className="item" key={index}>
              <div className="topperdiv">
                <div className="topperimages">
                    <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item1?.attachments}`} className="img-fluid"/>
                    </div>
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                    <div className="topperdesc">
                    <p className="per"> {item1?.division}</p>
                    <p className="name">{item1?.name}	 </p>
                    </div>
                </div>
           </div>
           ))}
           </Slider>
           ) : (
             <Slider {...settings}> 
               {emptyArray.map((data, index) => (
                 <div className="item" key={index}>
                    <div className="topperdiv">
                    <div className="topperimages">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/defaultImage.jpg" className="img-fluid"/>
                        </div>
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                        <div className="topperdesc">
                    <p className="per"> {data.division}</p>
                    <p className="name">{data.name}	  </p>
                    </div>
                    </div>
                    </div>
                    ))}
                    </Slider>
                  )}
                </div>
                {/* 
                <div className="item">
                <div className="topperdiv">
                <div className="topperimages">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/MARIYAM-KAHAN.jpg" className="img-fluid"/>
                </div>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                <div className="topperdesc">
                <p className="per">98.40% </p>
                <p className="name">MARIYAM KAHAN </p>
                </div>
                </div>
                </div>
                <div className="item">
                <div className="topperdiv">
                <div className="topperimages">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/SUKRITI-SRIVASTAVA.jpg" className="img-fluid"/>
                </div>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                <div className="topperdesc">
                <p className="per">98.40%</p>
                <p className="name">SUKRITI SRIVASTAVA</p>
                </div>
                </div>
                </div>
                <div className="item">
                <div className="topperdiv">
                <div className="topperimages">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/DIVYANKA-PATEL.jpg" className="img-fluid"/>
                </div>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                <div className="topperdesc">
                <p className="per"> 98%</p>
                <p className="name">DIVYANKA PATEL </p>
                </div>
                </div>
                </div>
                <div className="item">
                <div className="topperdiv">
                <div className="topperimages">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/YASIR-NADEEM-KHAN.jpg" className="img-fluid"/>
                </div>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                <div className="topperdesc">
                <p className="per">98%</p>
                <p className="name">YASIR NADEEM KHAN</p>
                </div>
                </div>
                </div>
                <div className="item">
                <div className="topperdiv">
                <div className="topperimages">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/SATYAM-SAMAR-PAL.jpg" className="img-fluid"/>
                </div>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                <div className="topperdesc">
                <p className="per"> 97.80%</p>
                <p className="name"> SATYAM SAMAR PAL</p>
                </div>
                </div>
                </div>
                <div className="item">
                <div className="topperdiv">
                <div className="topperimages">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/SHAURYA-SRIVASTAVA.jpg" className="img-fluid"/>
                </div>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                <div className="topperdesc">
                <p className="per">97.60%</p>
                <p className="name">SHAURYA SRIVASTAVA</p>
                </div>
                </div>
                </div>
                <div className="item">
                <div className="topperdiv">
                <div className="topperimages">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/KARTIKEY-TULSYAN.jpg" className="img-fluid"/>
                </div>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                <div className="topperdesc">
                <p className="per">97.20%</p>
                <p className="name"> KARTIKEY TULSYAN</p>
                </div>
                </div>
                </div>
                <div className="item">
                <div className="topperdiv">
                <div className="topperimages">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/AKHIL-VERMA.jpg" className="img-fluid"/>
                </div>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                <div className="topperdesc">
                <p className="per"> 97.20%</p>
                <p className="name">AKHIL VERMA </p>
                </div>
                </div>
                </div>
                */}
            
          
    
          
     </>
  )
}

export default TopperX