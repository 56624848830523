import React, { useEffect, useState } from 'react'
import {getSliders} from'../Service/Api'
const HomeSlider = () => {
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null); 
     useEffect(() => {
         const fetchData = async () =>{
                try{
                   const sliderData = await getSliders();
                   setData(sliderData) ;  
                   console.log(sliderData)
                }catch(error){
                 setError(error);
                }finally{
                 setLoading(false)
                }
         }
         fetchData();
     },[]);
     const emptyArray = [
          {id:1},
          {id:2},
          {id:3}
  
   ]
  return (
     <>
     <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
     <div className="carousel-inner">
         {data && data.length >0 ? (data.map((item,index) => (
           <div key={index} className={`carousel-item${index === 0 ? ' active' : ''}`}>
                <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.images}`} className="d-block w-100" alt="St. Basil School, Basti"/>
           </div>
         ))):(emptyArray.map((item,index) => (
           <div className={`carousel-item${index === 0 ? ' active' : ''}`} key={item.id}>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/slider0.jpg"  className="d-block w-100" alt="St. Basil School, Basti"/>
                 
             </div> 
         )))}
     </div>
    
      {/* <div className="carousel-inner">
     <div className="carousel-item active">
       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/slide1.jpg" className="d-block w-100" alt="St. Basil School, Basti"/>
     </div>
     <div className="carousel-item">
       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/slide2.jpg" className="d-block w-100" alt="St. Basil School, Basti"/>
     </div>
     <div className="carousel-item">
       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/slide3.jpg" className="d-block w-100" alt="St. Basil School, Basti"/>
     </div>
   </div> */}
   <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
     <span className="carousel-control-prev-icon" aria-hidden="true"></span>
     <span className="visually-hidden">Previous</span>
   </button>
   <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
     <span className="carousel-control-next-icon" aria-hidden="true"></span>
     <span className="visually-hidden">Next</span>
   </button>
 </div>


     </>
  )
}

export default HomeSlider
