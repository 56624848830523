import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick"; 
const Footer = () => {
    var settings = { 
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,  
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 1,
                slidesToScroll:1,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                slidesToScroll:1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:1,
                slidesToScroll: 1, 
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
    <>
    <div className="footer">  
       <div className="footerinfo">
           <h5>OUR BRANCHES</h5>
          <div className="row">
         
              <div className="col-lg-12 col-md-12">
              <Slider {...settings}>
                <div> 
                    <h6> St. Basil’s School</h6>
                    <p>Nipaniya, Samaspur, Basti, Uttar Pradesh - 272124</p>
                </div>
                <div>
                    <h6>Little Flower School</h6>
                    <p>Harraiya, Basti, Uttar Pradesh – 272155</p>
                </div>
                <div>
                    <h6>Little Flower School </h6>
                    <p>Rapti Nagar Phase - IV, Chargawan, Gorakhpur, Uttar Pradesh - 273 013</p>
                </div>
                <div>
                    <h6>Little Flower School</h6>
                    <p>Chero-Salempur Road, Salempur, Deoria, Uttar Pradesh – 274509</p>
                </div>
                <div>
                    <h6>Little Flower School</h6>
                    <p>Kalyan Bhadarsa, Ayodhya, Uttar Pradesh – 272235</p>
                </div>
                <div>
                    <h6>Little Flower School</h6>
                    <p>Khargora Basti, Ikauna, Shravasti, Uttar Pradesh –  271845</p>
                </div>
            </Slider>
                  
              </div> 
            </div>
       </div>
      <div className="container-fluid fulwidth">
        <div className="row">
            <div className="col-lg-12">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/footerlogo.png" class="img-fluid"/>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 divider">
                <div className="quick-link">
                    <ul>
                        <li><Link to="/Curriculum"> <i className="bi bi-caret-right-fill"></i> Curriculum </Link></li> 
                        <li><Link to="/Admission"> <i className="bi bi-caret-right-fill"></i>  Admission </Link></li> 
                        <li><Link to="#"> <i className="bi bi-caret-right-fill"></i> Sitemap </Link></li>
                        <li><Link to="#"> <i className="bi bi-caret-right-fill"></i> Careers </Link></li>
                    </ul>
                    <ul> 
                      <li><Link to="/Tc"> <i className="bi bi-caret-right-fill"></i>   Transfer Certificate </Link></li>
                        <li><Link to="/ErpSoftware"> <i className="bi bi-caret-right-fill"></i> Erp Software</Link></li>
                        <li><Link to="/Pphelp"> <i className="bi bi-caret-right-fill"></i>Parent Portal  </Link></li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="cont-dtl">
                    <h3>Location</h3>
                    <p><Link to="https://maps.app.goo.gl/XtMzcSAipXRCjTVB6" target="_blank"><i className="bi bi-geo-alt"></i> Khirighat, Madwanagar,  Basti, U.P. -272002 </Link></p>
                    <p><Link to="tel:+91 9452383085"><i className="bi bi-telephone-fill"></i> +91 9452383085</Link></p>
                    <p><Link to="mailto:stbasilbasti@gmail.com"><i className="bi bi-envelope"></i> stbasilbasti@gmail.com</Link></p>
                    <p><Link to="https://stbasilbasti.online/"><i className="bi bi-globe"></i> www.stbasilbasti.online</Link></p>
                    <p><Link to="http://www.youtube.com/@saintbasil8187"><i className="bi bi-youtube"></i>  http://www.youtube.com/@saintbasil8187</Link></p>
                </div>
            </div>
            
            <div className="col-lg-3 col-md-6 divider">
               <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d319545.1216099144!2d82.771909!3d26.827503!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3990d005de2926af%3A0xb8a774303c1ddfbc!2sSt.%20Basil&#39;s%20  School!5e1!3m2!1sen!2sin!4v1715233745283!5m2!1sen!2sin"  width="100%"  border="0px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
            <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="d-flex">
                    <div className="app-cont">
                        <p>Download mobile Apps -A Common Platform For Educators, Parents & Teachers</p>
                        <div>
                            <Link to="https://apps.apple.com/in/app/campuscare-10x/id1611283814" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/app1.png" className="img-fluid"/> </Link>
                            <Link to="https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/app2.png" className="img-fluid"/> </Link>
                        </div>
                        <p className="link">
                            Use School Code: STBASIL  
                        </p>
                    </div>
                    <div className="phone-img">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/app3.png" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
       </div>
       <div className="footerbottom">
        <div className="container-fluid fulwidth">
           <div className="row">
                <div class="col-lg-3">
                      <p class="hours">Working Hours</p>
                </div>
                <div class="col-lg-9">
                     <ul> 
                         <li>Weekdays : 08:00 AM to 04:00 PM</li>
                         <li>Saturdays : 08:00 AM to 02:00 PM</li>
                         <li>Sunday Closed</li>
                     </ul>
                </div>
           </div>
        </div>
       </div>
       <div className="copyright">
          <p>Copyright © 2024 St. Basil School, Basti, Uttar Pradesh, India | Designed and Developed by Entab Infotech : <Link to="https://www.entab.in/">CampusCare®️ </Link></p> 
      </div>
    </div> 
    </>
  )
}

export default Footer
