import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
const Header = () => {

    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);


  return (
        <>
        <div className="header">
        <div className="header-portalbtn">
           <NavLink to="https://entab.online/STBASIL" target="_blank" id='home'> Login Portal</NavLink>  
        </div>
        <div className="header-nav">
        <div className="logo">
            <NavLink to="/"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/Images/logo.png" alt="St. Basil School, Basti" className="img-fluid"/></NavLink>
        </div>
            <nav className="navbar navbar-expand-lg">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav">
                    <li className="nav-item active">  <NavLink className="nav-link">Home </NavLink> </li>
                    <li className="nav-item">  <NavLink className="nav-link" to="#">About Us </NavLink> </li> 
                    <li className="nav-item dropdown">
                       <div className="nav-link" data-bs-toggle="dropdown">   Academic  </div>
                        <ul className="dropdown-menu fade-up">
                           <li> <NavLink className="dropdown-item" to="/Curriculum"> Curriculum </NavLink></li>
                            <li> <NavLink className="dropdown-item" to="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/pdf/School-Election-results.pdf" target="_blank">  School Election Result</NavLink></li>
                            <li> <NavLink className="dropdown-item" to="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/pdf/LCA-ACADEMIC.pdf" target="_blank">  Academic Year </NavLink></li>
                        </ul>
                    </li>
                    <li className="nav-item">  <NavLink className="nav-link" to="#">Co-curricular </NavLink> </li>
                 
                    <li className="nav-item dropdown">
                    <NavLink className="nav-link" data-bs-toggle="dropdown">   Admission  </NavLink>
                     <ul className="dropdown-menu fade-up">
                        <li> <NavLink className="dropdown-item" to="/Admission"> Admission </NavLink></li>
                        <li> <NavLink className="dropdown-item" to="/RegistrationAdmission"> Registration and Admission </NavLink></li>
                         <li> <NavLink className="dropdown-item" to="https://d280nq1n4mqyso.cloudfront.net/api/image/SBSB/public/pdf/Fee.pdf" target="_blank"> Fee Structure </NavLink></li>
                     </ul>
                 </li>

                    <li className="nav-item">  <NavLink className="nav-link" to="/">Student Corner </NavLink> </li>
                    <li className="nav-item">  <NavLink className="nav-link" to="/News">News </NavLink> </li>
                    <li className="nav-item">  <NavLink className="nav-link" to="/Gallery">Gallery </NavLink> </li>
                    <li className="nav-item">  <NavLink className="nav-link" to="/ContactUs">Contact Us </NavLink> </li>
                </ul>
                </div> 
           </nav>
        </div>
        <div className="header-info">
             <div className="header-info-icn">
                  <NavLink to="tel:+91-9452383085"><i className="bi bi-telephone-fill"></i> +91-9452383085</NavLink>  
                  <NavLink to="mailto:stbasilbasti@gmail.com"><i className="bi bi-envelope-fill"></i> stbasilbasti@gmail.com</NavLink>  
             </div>
        </div>
    </div>
        </>
  )
}

export default Header
